<template>
  <div>
      <van-tabbar route active-color="#0957ff">
        <van-tabbar-item name="home" icon="wap-home" to="/itgs/">首页</van-tabbar-item>
        <van-tabbar-item name="location" icon="map-marked" to="/itgs/location">位置</van-tabbar-item>
        <van-tabbar-item name="app" icon="apps-o" to="/itgs/control">工作台</van-tabbar-item>
        <van-tabbar-item name="video" icon="video" to="/itgs/media">媒体</van-tabbar-item>
        <van-tabbar-item name="about" icon="setting" to="/itgs/about">关于</van-tabbar-item>
      </van-tabbar>
  </div>
</template>

<script>
export default {
    name: 'tabBar',
}
</script>

<style>

</style>